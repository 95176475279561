<template>
  <Row>
    <i-col span="10" class="align-justify">
      <Form ref="formValidate" :model="formItem" :rules="ruleValidate" :label-width="80">
        <FormItem required label="报告标题" prop="titleContent">
          <i-col span="22" class="align-justify">
            <Input
              v-model="formItem.titleContent"
              maxlength="50"
              show-word-limit
              size="large"
              placeholder="请输入报告标题"
            ></Input>
          </i-col>
        </FormItem>
        <FormItem label="页眉内容">
          <i-col span="22" class="align-justify">
            <Input
              v-model="formItem.pageHeaderContent"
              type="textarea"
              maxlength="30"
              show-word-limit
              :autosize="true"
              size="large"
              placeholder="请输入页眉内容"
            ></Input>
          </i-col>
        </FormItem>
        <FormItem label="报告Logo">
          <i-col span="16" class="align-justify">
            <Upload
              style="100px"
              type="drag"
              :format="['jpg','jpeg','png']"
              :on-success="handleSuccess"
              :on-error="handleError"
              :max-size="2048"
              :show-upload-list="false"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              action="https://dxzsadminapi.hxypsy.cn/api/Scale/UploadImage"
            >
              <div style="padding: 20px 0">
                <Icon type="ios-cloud-upload" size="35" style="color: #3399ff"></Icon>
                <p>点击或拖动图片到这里上传</p>
              </div>
            </Upload>
          </i-col>
          <i-col span="4" class="align-justify">
            <Avatar
              shape="square"
              :src="scaleimages"
              size="large"
              style="margin-left:30px;width: 100px;height: 100px;line-height:100px"
            />
          </i-col>
        </FormItem>
        <FormItem label="页脚内容">
          <i-col span="22" class="align-justify">
            <Input
              v-model="formItem.pageFooterContent"
              type="textarea"
              maxlength="50"
              show-word-limit
              :autosize="true"
              size="large"
              placeholder="请输入页脚内容"
            ></Input>
          </i-col>
        </FormItem>
        <FormItem required label="页面内容" prop="interest">
          <i-col span="22" class="align-justify">
            <CheckboxGroup v-model="formItem.interest">
              <Checkbox label="测评结果"></Checkbox>
              <Checkbox label="测评图示"></Checkbox>
              <Checkbox label="结果分析"></Checkbox>
              <Checkbox label="指导建议"></Checkbox>
            </CheckboxGroup>
          </i-col>
        </FormItem>
        <FormItem required label="被测信息">
          <i-col span="22" class="align-justify">
            <Transfer
              :data="data"
              :target-keys="targetKeys"
              :list-style="listStyle"
              :render-format="render"
              :titles="['未选信息','已选信息']"
              filterable
              @on-selected-change="testSelect"
              @on-change="handleChange"
            ></Transfer>
          </i-col>
        </FormItem>
        <FormItem>
          <i-col span="22" class="align-justify">
            <Button type="primary" @click="submitPrintSetting">提交</Button>
            <!-- <Button style="margin-left: 8px">预览</Button> -->
          </i-col>
        </FormItem>
      </Form>
      <Spin size="large" fix v-if="spinShow">
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
        <div>正在加载...</div>
      </Spin>
    </i-col>
  </Row>
</template>
<script>
export default {
  name: "PrintSetting",
  data() {
    return {
      scaleimages: "",
      spinShow: true,
      indeterminate: true,
      checkAll: false,
      checkAllGroup: [],
      testSelectKey: [], // 目前勾选的数据
      data: [],
      targetKeys: [],
      listStyle: {
        height: "400px",
      },
      formItem: {},
      fieldCollect: [],
      ruleValidate: {
        titleContent: [
          {
            required: true,
            message: "报告标题必须填写",
            trigger: "blur",
          },
        ],
        interest: [
          {
            required: true,
            type: "array",
            min: 1,
            message: "至少需要选择一个内容展示",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.getPrintSetting();
  },
  methods: {
    handleError() {
      this.$Message.error("上传图片失败，请重试");
    },
    handleSuccess(res, file) {
      this.imagesPath = res;
      this.scaleimages = "https://dxzsadminapi.hxypsy.cn" + res;
      this.$Message.success("上传图片成功");
    },
    handleFormatError(file) {
      this.$Notice.error({
        title: "错误",
        desc: "文件 " + file.name + " 格式错误, 请选择 jpg 或者 png.",
      });
    },
    handleMaxSize(file) {
      this.$Notice.error({
        title: "错误",
        desc: "文件 " + file.name + " 过大, 请选择不超过2M的文件.",
      });
    },
    submitPrintSetting() {
      this.$refs["formValidate"].validate((valid) => {
        if (valid) {
          if (this.targetKeys.length <= 0) {
            this.$Message.error("被测试人员基本信息至少需要选择1个!");
            return;
          } else {
            this.$Modal.confirm({
              title: "提示",
              content: "<p>确认修改报告配置?</p>",
              loading: true,
              onOk: () => {
                let baseFieldCollect = "";
                for (let i = 0; i < this.targetKeys.length; i++) {
                  baseFieldCollect += this.targetKeys[i] + ",";
                }
                let contentCollect = "";
                for (let i = 0; i < this.formItem.interest.length; i++) {
                  contentCollect += this.formItem.interest[i] + ",";
                }
                let postData = {
                  titleContent: this.formItem.titleContent,
                  pageHeaderContent: this.formItem.pageHeaderContent,
                  pageFooterContent: this.formItem.pageFooterContent,
                  baseFieldCollect: baseFieldCollect,
                  contentCollect: contentCollect,
                  logoPath: this.scaleimages,
                };
                this.$post("General/SubmitReportSetting", postData).then(
                  (res) => {
                    if (res.data.code) {
                      this.$Modal.remove();
                      this.$Message.success(res.data.msg);
                      this.getPrintSetting();
                    } else {
                      this.$Modal.remove();
                      this.$Message.error(res.data.msg);
                    }
                  }
                );
              },
              onCancel: () => {
                this.$Message.error("取消修改!");
              },
            });
          }
        } else {
          this.$Message.error("数据不完整!");
          return;
        }
      });
    },
    getPrintSetting() {
      this.spinShow = true;
      this.$get("General/GetReportSetting").then((res) => {
        this.formItem = res.data.response;
        this.scaleimages = res.data.response.logoPath;
        this.$set(this.formItem, "interest", []);
        if (res.data.response.contentStatus == 1) {
          this.formItem.interest.push("测评结果");
        }
        if (res.data.response.chartsStatus == 1) {
          this.formItem.interest.push("测评图示");
        }
        if (res.data.response.resultStatus == 1) {
          this.formItem.interest.push("结果分析");
        }
        if (res.data.response.adviceStatus == 1) {
          this.formItem.interest.push("指导建议");
        }
        this.$get("General/GetBaseField", {
          fieldCollect: res.data.response.baseFieldCollect,
        }).then((res) => {
          this.data = res.data.response.fieldList;
          this.targetKeys = res.data.response.alreadyFieldList;
          this.spinShow = false;
        });
      });
    },
    getTargetKeys() {
      return this.getAllBaseField();
    },
    handleChange(newTargetKeys, moveKeys, direction) {
      if (newTargetKeys.length > 15) {
        this.$Message.error("最多只可显示15个字段内容");
        return;
      }
      if (moveKeys == "right") {
        for (let i = 0; i < direction.length; i++) {
          this.targetKeys.push(direction[i]);
        }
      } else {
        this.targetKeys = newTargetKeys;
      }
    },
    render(item) {
      return item.label;
    },
    // 获取当前选中的值
    testSelect: function (sourceSelectedKeys, targetSelectedKeys) {
      this.testSelectKey = targetSelectedKeys;
    },
    // 排序方法
    testSort: function (sortType) {
      // 判断当前选择的数据是否为1个
      if (this.testSelectKey.length > 1) {
        this.$Message.warning("排序时只能选择1个");
        return;
      } else if (this.testSelectKey.length <= 0) {
        this.$Message.warning("请至少选择一个");
        return;
      } else {
        // 上移
        if (sortType === 1) {
          let arrIndex = this.targetKeys.indexOf(this.testSelectKey[0]);
          if (arrIndex === 0) {
            this.$Message.warning("已经是顶部了");
          } else {
            // 交换数组序列
            let up = this.targetKeys[arrIndex - 1];
            let now = this.targetKeys[arrIndex];
            this.targetKeys[arrIndex] = up;
            this.targetKeys[arrIndex - 1] = now;
          }
        }
        // 下移
        if (sortType === 2) {
          let arrIndex = this.targetKeys.indexOf(this.testSelectKey[0]);
          if (arrIndex === this.targetKeys.length - 1) {
            this.$Message.warning("已经是底部了");
          } else {
            // 交换数组序列
            let up = this.targetKeys[arrIndex + 1];
            let now = this.targetKeys[arrIndex];
            this.targetKeys[arrIndex] = up;
            this.targetKeys[arrIndex + 1] = now;
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>